import { graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import { PageWrapper } from '../components/layout/PageWrapper';
import { CategoriesStream } from '../components/layout/sections/CategoriesStream';
import { HeroClip } from '../components/layout/sections/SectionHero';
import { AboutTextBox } from '../components/misc/HeroContent';

const ProductsPage = ({
  data: {
    datoCmsProductsPage: {
      heroImage: { gatsbyImageData },
      heroImageMedium: { heroImageMediumData },
      heroTitle,
      heroSubtitle,
      seo,
    },
    categories: { otherCategoriesNodes },
  },
  pageContext,
}) => {
  const directedImages = withArtDirection(getImage(gatsbyImageData), [
    {
      media: '(max-width: 810px)',
      image: getImage(heroImageMediumData),
    },
  ]);
  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <HeroClip
        scale="true"
        maxHeight="800px"
        overlay
        $minHeightMobile="600px"
        $gatsbyImageMaxHeight="800px"
        clipHeight="800px"
        imageData={directedImages}
        clipBackground="var(--gold-3)"
        contentBox={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <AboutTextBox
            heroTitle={heroTitle}
            heroSubtitle={heroSubtitle}
            paddingBottom="110px"
          />
        }
      />
      <CategoriesStream categoriesArray={otherCategoriesNodes} />
    </PageWrapper>
  );
};
export default ProductsPage;

export const query = graphql`
  query ProductsRootPageQuery($locale: String!) {
    datoCmsProductsPage(locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      locale
      heroImage {
        gatsbyImageData
      }
      heroImageMedium {
        heroImageMediumData: gatsbyImageData
      }
      heroTitle
      heroSubtitle
    }
    categories: allDatoCmsCategory(
      sort: { order: ASC, fields: position }
      filter: { locale: { eq: $locale } }
    ) {
      otherCategoriesNodes: nodes {
        comingSoon
        originalId
        title
        slug
        thumbnail {
          gatsbyImageData
        }
      }
    }
  }
`;
